import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { EditorUser } from '../../../../../types/Editor';
import Avatar from '../../../../design_system/display/avatar';
import { fontSm5 } from '../../../../styled/TypeSystem';
import AdditionalUsers from './AdditionalUsers';

const CollaborationUsersWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const AvatarsContainer = styled.div`
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
`;

const AvatarWrapper = styled.div<{ color: string }>`
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
  border: ${({ theme: { constants }, color }) => `${constants.borderWidthLg} solid ${color}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusCircle};
`;

const AvatarTooltip = styled(ReactTooltip)`
  padding: ${({ theme: { constants } }) => constants.spacerSm3} !important;
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  ${fontSm5};
`;

export type Props = {
  displayedUserCount?: number;
  users: EditorUser[];
};

const CollaborationUsers = ({ displayedUserCount = 3, users }: Props) => {
  const displayedCollabUsers = users.slice(0, displayedUserCount);
  const additionalUsers = users.slice(displayedUserCount);

  return (
    <CollaborationUsersWrapper>
      {displayedCollabUsers.length > 0 && (
        <AvatarsContainer>
          {displayedCollabUsers.map(({ avatarUrl, clientId, color, name }) => (
            <Fragment key={clientId}>
              <AvatarTooltip
                arrowColor='transparent'
                backgroundColor={color}
                effect='solid'
                id={`avatar-${clientId}-tooltip`}
                offset={{ top: 35, left: -15 }}
                place='left'
              >
                {name}
              </AvatarTooltip>
              <AvatarWrapper color={color} data-for={`avatar-${clientId}-tooltip`} data-tip>
                <Avatar image={avatarUrl} name={name} shape='circle' size='xs' />
              </AvatarWrapper>
            </Fragment>
          ))}
        </AvatarsContainer>
      )}
      {additionalUsers.length > 0 && <AdditionalUsers additionalUsers={additionalUsers} />}
    </CollaborationUsersWrapper>
  );
};

export default CollaborationUsers;
