import { TiptapCollabProvider, WebSocketStatus } from '@hocuspocus/provider';
import React, { useCallback, useMemo, useState } from 'react';

import { EditorProvider } from '../../../../contexts/EditorContext';
import { useEditorCourseContext } from '../../../../contexts/EditorCourseContext';
import { EditorToolbarProvider } from '../../../../contexts/EditorToolbarContext';
import useActionCableChannel from '../../../../hooks/useActionCableChannel';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { KeysToSnakeCase, toCamelCase } from '../../../../lib/keyFormatConverter';
import { stepsApi } from '../../../../redux/services/resourceApis/steps/stepsApi';
import { GetStepResponse } from '../../../../redux/services/resourceApis/steps/types';
import { trainualApi } from '../../../../redux/services/trainualService';
import { store } from '../../../../redux/stores/store';
import BubbleMenuComponent from '../components/BubbleMenu/BubbleMenu';
import StepContentSkeleton from '../components/StepContentSkeleton/StepContentSkeleton';
import { BaseContentWrapper } from '../shared/styles';
import { stepEditorBubbleToolbarButtons } from '../toolbar/buttons/shared';
import Toolbar from '../toolbar/Toolbar';
import ToolbarSkeleton from './components/EditModeSkeleton/ToolbarSkeleton/ToolbarSkeleton';
import Editor from './Editor/Editor';
import { useEditableContent } from './hooks/useEditableContent';
import EditorLockedOverlay from './LockedOverlay';

type GetStepResponseSnakeCase = KeysToSnakeCase<GetStepResponse>;
type StepActionCableResponse =
  | { status: 'reading_time_updated' }
  | { status: 'content_updated'; step: GetStepResponseSnakeCase };

type Props = {
  collabState: WebSocketStatus;
  provider: TiptapCollabProvider;
  stepData: GetStepResponse;
};

const EditorContent = ({ collabState, provider, stepData }: Props) => {
  const [isFetching, setIsFetching] = useState(false);
  const { course, curriculum } = useEditorCourseContext();
  const account = useCurrentAccount();
  const { slug } = account;

  const { collabUsers, editor, isAILoading } = useEditableContent({
    provider,
  });

  const stepChannelProps = useMemo(() => {
    return {
      channel: 'StepContentChannel',
      step_id: String(stepData.id),
    };
  }, [stepData.id]);

  const handleReceived = useCallback(
    (data: StepActionCableResponse) => {
      if (data.status === 'reading_time_updated') {
        store.dispatch(trainualApi.util.invalidateTags([{ type: 'Topic', id: course.id }]));
      }

      if (data.status === 'content_updated') {
        setIsFetching(true);

        setTimeout(() => {
          const transformedStepData = toCamelCase(data.step) as unknown as GetStepResponse;

          store.dispatch(
            stepsApi.util.updateQueryData('getStep', stepData.id, (draft) => {
              draft.updatedAtData = transformedStepData.updatedAtData;
            })
          );

          setIsFetching(false);
        }, 500);
      }
    },
    [course.id, stepData.id]
  );

  useActionCableChannel<StepActionCableResponse>(stepChannelProps, handleReceived);

  if (!editor) {
    return (
      <BaseContentWrapper>
        <ToolbarSkeleton />
        <StepContentSkeleton editMode />;
      </BaseContentWrapper>
    );
  }

  const lockedBySignatures = course.eSignatureDisplayedAndRequired && !course.canBeModified;
  const isLocked = lockedBySignatures || curriculum.locked;

  if (isLocked) {
    editor.setOptions({ editable: false });
  }

  return (
    <EditorProvider editor={editor} isAILoading={isAILoading}>
      <EditorToolbarProvider buttons={stepEditorBubbleToolbarButtons} context='bubble'>
        <BubbleMenuComponent />
      </EditorToolbarProvider>
      {isLocked && <EditorLockedOverlay course={course} curriculum={curriculum} slug={slug} />}
      <BaseContentWrapper>
        <Toolbar isFetching={isFetching} isLocked={isLocked} step={stepData} />
        <Editor collabState={collabState} collabUsers={collabUsers} step={stepData} />
      </BaseContentWrapper>
    </EditorProvider>
  );
};

export default EditorContent;
